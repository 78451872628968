import Axios from "axios";

Axios.defaults.baseURL = "https://desafiosbackend.directy.com.br/";
// Axios.defaults.baseURL = "http://localhost:62399/";
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.headers.put["Content-Type"] = "application/json";
Axios.defaults.headers.patch["Content-Type"] = "application/json";

function GetToken() {
    let loginCandidato = sessionStorage.getItem("loginCandidato");

    if (!loginCandidato) {
        throw new Error("Login não registrado");
    }

    let obj_loginCandidato = JSON.parse(loginCandidato);
    if (typeof obj_loginCandidato != "object") {
        throw new Error("Login salvo inválido");
    }

    let token = obj_loginCandidato.token;
    if (!token) {
        throw new Error("Token não registrado");
    }

    return token;
}

function GetUserId() {
    let loginCandidato = sessionStorage.getItem("loginCandidato");

    if (!loginCandidato) {
        throw new Error("Login não registrado");
    }

    let obj_loginCandidato = JSON.parse(loginCandidato);
    if (typeof obj_loginCandidato != "object") {
        throw new Error("Login salvo inválido");
    }

    let userId = obj_loginCandidato.id;
    if (!userId) {
        throw new Error("Id de usuário não registrado");
    }

    return userId;
}

function GetConfig(token = "") {
    return {
        headers: {
            "x-access-token": token,
        },
    };
}

class Api {
    static async LoginCandidato(email, senha) {
        let obj = {
            email,
            senha,
        };

        return Axios.post("/candidato/login", obj).then((res) => res.data);
    }

    static async GetDesafiosCandidato() {
        let token = GetToken();
        let userId = GetUserId();

        let config = GetConfig(token);

        return Axios.get(`/candidato/${userId}/realizados`, config).then((res) => res.data);
    }

    static async GetDesafioId(realizadoId) {
        let token = GetToken();

        let config = GetConfig(token);

        return Axios.get(`/realizado/${realizadoId}`, config).then((res) => res.data);
    }

    static async PostNovaSenha(email) {
        let obj = {
            email,
        };

        return Axios.post(`/candidato/novaSenha`, obj).then((res) => res.data);
    }

    static async PutRealizado(realizadoId, github, feedback) {
        let token = GetToken();

        let config = GetConfig(token);

        let obj = {
            realizadoId,
            github,
            feedback,
        };

        return Axios.put(`/realizado`, obj, config).then((res) => res.data);
    }
}

export default Api;
